<template>
  <div class="blog-post markdown-body" v-html="markdownContent" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useRoute } from "vue-router"
import markdownParser from "@/markdownParser"
import Prism from "prismjs"
import "prismjs/components/prism-javascript"
import "prismjs/themes/prism-okaidia.css" // okaidia theme

const route = useRoute()
const markdownContent = ref("")

const loadMarkdownContent = async (
  year: string | string[],
  slug: string | string[]
) => {
  const markdownPath = `/markdowns/${year}/${slug}.md`

  try {
    const response = await fetch(markdownPath)
    if (!response.ok) {
      throw new Error(`Markdown file not found: ${markdownPath}`)
    }
    const text = await response.text()
    return text
  } catch (error) {
    console.error("Error fetching markdown file:", error)
    return ""
  }
}

watch(
  () => route.params,
  async (params) => {
    const { year, slug } = params

    const markdownText = await loadMarkdownContent(year, slug)
    markdownContent.value = markdownParser.render(markdownText)

    Prism.highlightAll()
  },
  { immediate: true }
)
</script>
<style>
.markdown-body h1 {
  font-size: 2em;
  font-weight: bold;
}

.markdown-body h2 {
  font-size: 1.75em;
  font-weight: bold;
}

.markdown-body h3 {
  font-size: 1.5em;
}

.markdown-body ul {
  list-style-type: disc;
  margin-left: 20px;
}

.markdown-body ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.markdown-body li {
  margin-bottom: 5px;
}

.markdown-body table {
  width: 100%;
  border-collapse: collapse;
}

.markdown-body th,
.markdown-body td {
  border: 1px solid #ddd;
  padding: 8px;
}

.markdown-body th {
  background-color: #f0f0f0;
}

.markdown-body blockquote {
  background-color: #272822;
  color: #16a34a;
  border-left: 4px solid #16a34a;
  padding: 10px 20px;
  margin: 10px 0;
  font-style: italic;
}

.markdown-body pre {
  background-color: #272822;
  border: 1px solid #ddd;
  padding: 10px;
  overflow: auto;
  font-family: "Courier New", monospace;
  line-height: 1.5;
}

.markdown-body code {
  font-family: "Courier New", monospace;
  color: #16a34a;
}

.markdown-body :not(pre) > code {
  background-color: #272822;
  color: #16a34a;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.95em;
  border: 1px solid #ddd;
}
</style>
