<template>
  <v-container>
    <v-row justify="start">
      <v-col cols="12" md="8">
        <v-list>
          <template v-for="post in blogPosts" :key="post.slug">
            <v-list-item class="align-center">
              <div>
                <v-list-item-subtitle class="date">
                  {{ post.date }}
                </v-list-item-subtitle>
              </div>
              <div class="ml-4">
                <v-list-item-title class="text-darkText">
                  <router-link :to="`/blog/${post.year}/${post.slug}`">
                    {{ post.title }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle class="text-darkText">
                  {{ post.summary }}
                </v-list-item-subtitle>
              </div>
            </v-list-item>
            <v-divider class="my-4" />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  data() {
    return {
      blogPosts: [
        {
          title: "Moving On",
          year: "2024",
          slug: "moving-on",
          date: "July 22, 2024",
          summary: "I was laid off on Friday of last week...",
        },
        {
          title: "First Blog Post",
          year: "2023",
          slug: "first-blog-post",
          date: "November 28, 2023",
          summary:
            "I finally got this blog working after a long lull spending too much of my time...",
        },
      ],
    }
  },
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.v-list-item {
  margin-bottom: 10px;
}
.v-list-item-subtitle {
  color: #fff;
}
.v-list-item-text {
  margin-top: 5px;
}
.date {
  color: #000;
  font-weight: bold;
}
.v-list-item-content {
  align-items: center;
}
.ml-4 {
  margin-left: 16px;
}
</style>
