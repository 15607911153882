export const baseTheme = {
  dark: false,
  colors: {
    background: "#9CA3AF",
    surface: "#9CA3AF",
    primary: "#16A34A",
    "primary-darken-1": "#006135",
    secondary: "#00bcd4",
    "secondary-darken-1": "#018786",
    accent: "#cddc39",
    error: "#f44336",
    warning: "#ff9800",
    info: "#009688",
    success: "#2196f3",
    darkText: "#2c3e50",
  },
}
