<template>
  <div class="flex flex-row items-center">
    {{ message }}
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"

const message = ref("")
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
