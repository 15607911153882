import { createApp } from "vue"
import "./style.css" // Add tailwind css styles
import App from "./App.vue"
import router from "./router"

//Sentry
import * as Sentry from "@sentry/vue"

// Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import { baseTheme } from "./themes/baseTheme" // Add base vuetify themes styles

// Globally add components throughout the app from vuetify here
import { VTabs } from "vuetify/components/VTabs"
import { VFooter } from "vuetify/components/VFooter"
import { VCheckbox } from "vuetify/components/VCheckbox"

const vuetify = createVuetify({
  theme: {
    defaultTheme: "baseTheme",
    themes: {
      baseTheme,
    },
  },
  components: {
    VTabs,
    VFooter,
    VCheckbox,
    // VListItem, VListItemContent, VListItemGroup, VListItemText
  },
})

const app1 = createApp(App)

Sentry.init({
  // Passing in `Vue` is optional, if you do not pass it `window.Vue` must be present.
  Vue: app1,
  dsn: "https://54b62cfbbc464d80b31f110a7c45d416@o557579.ingest.sentry.io/5690010",
  // Add tunnel for ublock origin cors issue.  See https://github.com/getsentry/sentry-javascript/issues/2916#issuecomment-1036564861
  tunnel: "/unblocksentry",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app1.use(router) // Add vue-router v4
app1.use(vuetify) // Add vuetify
app1.mount("#app") // Mount main app
