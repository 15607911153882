<template>
  <div class="svg-wrapper">
    <component
      :is="props.svg"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    />
  </div>
</template>

<script setup lang="ts">
import type { Component } from "vue"

interface Props {
  svg: Component | string
}

const props = defineProps<Props>()
</script>

<style scoped>
.svg-wrapper {
  width: 100%;
  height: 100%;
}
</style>
