import MarkdownIt from "markdown-it"
import markdownItPrism from "markdown-it-prism"
import markdownItFootnote from "markdown-it-footnote"
import markdownItSub from "markdown-it-sub"
import markdownItSup from "markdown-it-sup"
import lineNumbers from "./lineNumbers"

const markdown = new MarkdownIt({
  html: true,
  typographer: true,
})
  .use(markdownItPrism)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .use(markdownItFootnote as any)
  .use(markdownItSub)
  .use(markdownItSup)

const originalHighlight = markdown.options.highlight as (
  str: string,
  lang: string,
  attrs: string
) => string

markdown.options.highlight = lineNumbers(originalHighlight)

export default markdown
