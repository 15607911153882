<template>
  <v-app id="nav" class="text-darkText">
    <transition name="slide-fade">
      <v-app-bar v-if="top">
        <v-container class="mx-auto d-flex align-center justify-center">
          <v-tabs v-if="footerQuoteLoaded" v-model="tab" bg-color="primary">
            <v-tab value="home" @click="() => router.push('/')"> Home </v-tab>

            <v-tab value="blog" @click="() => router.push('/blog')">
              Blog
            </v-tab>

            <v-tab value="about" @click="() => router.push('/about')">
              About
            </v-tab>

            <v-tab value="typing" @click="() => router.push('/PerfectTyping')">
              Typing
            </v-tab>
          </v-tabs>
        </v-container>
      </v-app-bar>
    </transition>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-layout justify-center align-center>
      <v-footer v-if="footerQuoteLoaded" class="text-darkText footer">
        <v-row class="justify-center">
          <v-col cols="auto">
            <div>
              {{ footerQuote }}
            </div>
            <div>
              {{ footerMessage }}
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router"
import { onMounted, ref, onBeforeUnmount, watch } from "vue"

const router = useRouter()
const route = useRoute()

const footerQuote = ref<string>("")
const footerQuoteLoaded = ref<boolean>(false)
const footerMessage = ref<string>(
  `Gordon Cheng 2020-${new Date().getFullYear()}`
)
const tab = ref<string | null>(null)
const top = ref<boolean>(true)

watch(
  () => route.path,
  (newPath) => {
    // Update the tab based on the new path
    if (newPath === "/") {
      tab.value = "home"
    } else if (newPath.startsWith("/blog")) {
      tab.value = "blog"
    } else if (newPath === "/about") {
      tab.value = "about"
    } else if (newPath.startsWith("/PerfectTyping")) {
      tab.value = "typing"
    } else {
      tab.value = null // or a default value
    }
  },
  { immediate: true } // This ensures the watcher runs on initial load as well
)

let lastScrollTop = 0

function handleScroll() {
  const st = window.pageYOffset || document.documentElement.scrollTop
  lastScrollTop = st <= 0 ? 0 : st
  const newTopValue = lastScrollTop <= 5
  top.value = newTopValue
}

window.addEventListener("scroll", handleScroll), { passive: true }

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})

onMounted(async () => {
  try {
    const response = await fetch("/quote.json")
    if (!response.ok) {
      console.error("Failed to fetch the quote:", response.statusText)
      return
    }

    const body = await response.json()
    const minLength = 0
    const maxLength = body.length
    const randIndex =
      Math.floor(Math.random() * (maxLength - minLength)) + minLength
    const isAuthorNull = body[randIndex].author

    if (isAuthorNull === null) {
      footerQuote.value = body[randIndex].text
    } else {
      footerQuote.value = `${body[randIndex].text} - ${body[randIndex].author}`
    }
    footerQuoteLoaded.value = true
  } catch (error) {
    console.error("An error occurred:", error)
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
  color: #b9ffc5;
}

/**
Footer styles
*/
.footer {
  text-align: center;
}

/* Slide & Fade Transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out !important;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
}
.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
</style>
