type HighlightFunction = (text: string, lang: string, attrs: string) => string

export default function lineNumbers(
  originalHighlight: HighlightFunction
): HighlightFunction {
  return (text: string, lang: string, attrs: string) => {
    const html = originalHighlight(text, lang, attrs)
    const lines = html.split("\n").slice(0, -1)
    return lines
      .map((line) => `<span class="highlight-line">${line}</span>`)
      .join("\n")
  }
}
