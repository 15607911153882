<template>
  <v-container class="text-darkText tw-bg-gray-300 pa-0 ml-5 mr-2">
    <v-row align="center" justify="end" class="mr-2">
      <v-col cols="auto" class="pa-0">
        <label for="input1">Remove Capital Letters</label>
      </v-col>
      <v-col cols="auto" class="pa-0">
        <v-checkbox
          id="input1"
          v-model="optionCapitals"
          color="primary"
          hide-details
          @update:model-value="checkedOptionCapitals()"
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="end" class="mr-2">
      <v-col cols="auto" class="pa-0">
        <label for="input2">Remove Punctuation</label>
      </v-col>
      <v-col cols="auto" class="pa-0">
        <v-checkbox
          id="input2"
          v-model="optionPunctuation"
          color="primary"
          hide-details
          @update:model-value="checkedOptionPunctuation()"
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="end" class="mr-2">
      <v-col cols="auto" class="pa-0">
        <label for="input3">Mute the BG music</label>
      </v-col>
      <v-col cols="auto" class="pa-0">
        <v-checkbox
          id="input3"
          v-model="optionMute"
          color="primary"
          hide-details
          @update:model-value="checkedOptionMute()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
const emit = defineEmits(["capitals", "punct", "mute"])
import { ref, onMounted } from "vue"

const optionCapitals = ref(false)
const optionPunctuation = ref(false)
const optionMute = ref(false)

onMounted(() => {
  optionCapitals.value = localStorage.getItem("optionCapitals") === "true"
  optionPunctuation.value = localStorage.getItem("optionPunctuation") === "true"
  optionMute.value = localStorage.getItem("optionMute") === "true"
})

const checkedOptionCapitals = () => {
  localStorage.setItem("optionCapitals", optionCapitals.value.toString())
  emit("capitals", optionCapitals.value)
}

const checkedOptionPunctuation = () => {
  localStorage.setItem("optionPunctuation", optionPunctuation.value.toString())
  emit("punct", optionPunctuation.value)
}

const checkedOptionMute = () => {
  localStorage.setItem("optionMute", optionMute.value.toString())
  emit("mute", optionMute.value)
}
</script>

<style>
/* Changing the checkbox's unchecked state color */
.v-input--checkbox .v-input__control .v-icon {
  color: red; /* Change this to your preferred color */
}

/* Changing the checkbox's checked state color */
.v-input--checkbox.v-input--is-dirty .v-input__control .v-icon {
  color: green; /* Change this to your preferred color */
}

/* Changing the checkbox's hover color */
.v-input--checkbox:hover .v-input__control .v-icon {
  color: blue; /* Change this to your preferred color */
}

label {
  cursor: pointer;
  user-select: none;
}
</style>
