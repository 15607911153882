import { onMounted, onUnmounted } from "vue"

export default function onKeyDown(handler: (event: KeyboardEvent) => void) {
  onMounted(() => {
    window.addEventListener("keypress", handler)
  })

  onUnmounted(() => {
    window.removeEventListener("keypress", handler)
  })
}
